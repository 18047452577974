<template>
  <div class="mt-2">
    <div class="row">
      <div class="col-md-12 col-xl-12">
        <h4 class="d-inline"><i class="fa fa-chart-line"></i> {{chartName}}</h4>
      </div>
    </div>
    <div id="invoice-chart" class="mt-2">
      <rotate-square2 id="loading-gif" v-show="loading"></rotate-square2>
      <div id="chartdiv" style="width: 100%; height: 640px;"></div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import {RotateSquare2} from 'vue-loading-spinner'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dataviz from "@amcharts/amcharts4/themes/dataviz";

am4core.useTheme(am4themes_animated);
//am4core.useTheme(am4themes_material);
am4core.useTheme(am4themes_dataviz);

export default {
  name: 'Chart2',
  props: ['chartData', 'chartName'],
  components: {
    Multiselect,
    RotateSquare2,
  },
  data: function() {
    return {
      projectData: [],
      projectid: [],
      //chartData: [],
      cats: [],
      catData: [],
      catList: [],
      categoryid: [],
      loading: false,
    }
  },
  watch: {
    chartData: function(val) {
      this.createChart(val);
    }
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  methods: {
    async createChart(apiData) {
      var chart = am4core.create("chartdiv", am4charts.XYChart);

      chart.colors.list = [
        am4core.color("#5c99d6"),
        am4core.color("#f07c2d"),
        am4core.color("#a2a5a5"),
        am4core.color("#72ac4e"),
        am4core.color("#947500")
      ];

      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "label";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 10;

      categoryAxis.renderer.labels.template.rotation = 320;
      categoryAxis.renderer.cellStartLocation = 0.2;
      categoryAxis.renderer.cellEndLocation = 0.8;

      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

      valueAxis.title.text = "";
      valueAxis.calculateTotals = true;

      valueAxis.maxPrecision = 0;

      // Create series
      function createSeries(field, name) {
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.name = name;
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "label";

        // Make it stacked
        series.stacked = true;
        series.columns.template.width = am4core.percent(60);
        //series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryX}: ${valueY} - {Breakdown}";
        series.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryX}: ${valueY} \n [bold]Breakdown:[/] \n {Breakdown}[/]";

        var labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = "${valueY}";
        labelBullet.locationY = 0.5;

        return series;
      }

      let seriesMap = Object.keys(Object.assign({}, ...apiData)).filter(x=>x != 'label' && x != 'Breakdown')
      console.log(seriesMap)
      for (var i = 0; i < seriesMap.length; i++) {
        createSeries(seriesMap[i], seriesMap[i].replace(/_/g, ' '));
      }

      chart.scrollbarX = new am4core.Scrollbar();
      chart.cursor = new am4charts.XYCursor();

      // Add legend
      chart.legend = new am4charts.Legend();
      chart.legend.margin('10', '0', '0', '0')
      chart.legend.position = "bottom";

      chart.legend.useDefaultMarker = true;
      var marker = chart.legend.markers.template.children.getIndex(0);
      marker.cornerRadius(12, 12, 12, 12);
      marker.strokeWidth = 2;
      marker.strokeOpacity = 1;
      marker.stroke = am4core.color("#ccc");
      //chart.legend.markers.template.disabled = true;

      chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.filePrefix = "Invoice Chart Export";
      chart.exporting.menu.items = [{
        "label": "Export <i class='fa fa-export'></i>",
        "menu": [
          {
            "label": "Image",
            "menu": [
              { "type": "png", "label": "PNG" },
              { "type": "jpg", "label": "JPG" },
              { "type": "gif", "label": "GIF" },
              { "type": "svg", "label": "SVG" },
            ]
          }, {
            "label": "Data",
            "menu": [
              { "type": "json", "label": "JSON" },
              { "type": "csv", "label": "CSV" },
              { "type": "xlsx", "label": "XLSX" }
            ]
          }, {
            "label": "Print", "type": "print"
          }
        ]
      }];
      chart.data = apiData;
      // Legend
      this.loading = false;
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
  .mx-input {
    padding: 20px 15px !important;
  }

  #invoice-chart {
    position: relative;
    .amcharts-amexport-item.amcharts-amexport-item-level-0 {
      top: -50px;
      width: 100px;
      height: inherit;
      padding: 0.5em;
    }
    .amcharts-amexport-item {
      border: none;
      background: #4dbd74;

      a {
        color: #fff;
      }
    }
    .canvasjs-chart-credit {
      display: none !important;
    }

    #loading-gif {
      z-index: 9999;
      position: absolute;
      top: 200px;
      right: 50%;
    }
  }
</style>
