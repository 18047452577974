<template>
  <b-card>
    <ChartTemplate v-bind:chartName="chartName" v-bind:chartData="chartData"></ChartTemplate>
  </b-card>
</template>

<script>
import ChartTemplate from './../../components/ChartTemplate'

export default {
  name: 'ManagerCharts',
  components: {
    ChartTemplate,
  },
  data: function() {
    return {
      projectData: [],
      projectid: [],
      chartData: [],
      cats: [],
      catData: [],
      catList: [],
      categoryid: [],
      loading: false,
      chartName: "",
    }
  },
  methods: {

  },
  mounted() {
    this.chartName = "Completions Actual Well Cost Nest Only";
    this.chartData = [{
      "Drilling": "15,000.00",
      "Completions": "400,000.00",
      "label": "Budget",
      "Breakdown": "Well 1: $20000\nWell 2: $30000"
    },
    {
      "Drilling": "14,305.22",
      "Completions": "995,721.34",
      "label": "YTD",
      "Breakdown": "Well 1: $20000\nWell 2: $30000"
    },
    {
      "Drilling": "514,305.22",
      "Completions": "628,114.85",
      "label": "06-2019",
      "Breakdown": "Well 1: $20000\nWell 2: $30000"
    },
    {
      "Drilling": "514,305.22",
      "Completions": "628,114.85",
      "label": "Q2-2019",
      "Breakdown": "Well 1: $20000\nWell 2: $30000"
    },
    {
      "Completions": "367,606.49",
      "label": "07-2019",
      "Breakdown": "Well 1: $20000\nWell 2: $30000"
    },
    {
      "Completions": "367,606.49",
      "label": "Q3-2019",
      "Breakdown": "Well 1: $20000\nWell 2: $30000"
    }];

    this.$axios.get('manager/get/projects').then(response => {
      this.projectData = response.data.result.reverse();
    })
    .catch(error => {

    })
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
  .mx-input {
    padding: 20px 15px !important;
  }
</style>
